// Dependencies
// -----------------------------------------------
import React from 'react';
import axios from 'axios';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import ReactI18n from 'react-i18n';
import styled from 'styled-components';

// Components
// -----------------------------------------------
import { toast } from 'react-toastify';
import {
  Box,
  Divider,
  FlexBox,
  FormField,
  IconFontAwesome,
  InfoPanel,
  InputCheckbox,
  Label,
  Spacer,
  StandByOverlay,
  TextBody,
  TextDense,
  TextH2,
  Modal
} from '@directsoftware/ui-kit-web-admin';
import {
  cardTypeMask,
  checkErrorsGuests,
  checkErrorsCustomerEmail,
  checkErrorsCustomerName,
  checkErrorsCustomerPostalCode,
  checkErrorsCustomerTelephone,
  validateGuests,
  validateCustomerEmail,
  validateCustomerName,
  validateCustomerPostalCode,
  validateCustomerTelephone
} from '../credit-card';
import ErrorsPaymentCustomer from '../errors/payment-customer';
import CardSectionV2 from './card-section-v2';
import CheckoutCtaV2 from '../shared/checkout-cta-v2';
import { formatCurrency } from '../../../shared/helpers';

// Styles
// -----------------------------------------------
const ErrorLabel = styled.span`
  color: red;
  font-size: 12px;
  line-height: 24px;
`;

const FormGroup = styled.fieldset`
  display: block;
  width: 100%;

  &.width-25 {
    width: 23%;
  }

  &.width-50 {
    width: 48%;
  }

  &.width-75 {
    width: 73%;
  }
`;

// -----------------------------------------------
// COMPONENT->CARD-SETUP-FORM --------------------
// -----------------------------------------------
class CardSetupFormV2 extends React.Component {
  // Constructor
  // ---------------------------------------------
  constructor(props) {
    super(props);
    this.state = {
      toggleFill: false,
      guests: this.props.guests || 1,
      cardNumber: '',
      cardExpiry: '',
      cardCvv: '',
      cardTypeMask: '1111 1111 1111 1111',
      customerEmail: this.props.customerEmail || '',
      customerName: this.props.customerName || '',
      customerPostalCode: this.props.customerPostalCode || '',
      customerTelephone: this.props.customerTelephone || '',
      customerDateOfBirth: '',
      guestsValid: true,
      cardNumberValid: false,
      cardExpiryValid: false,
      cardCvvValid: false,
      customerEmailValid: false,
      customerNameValid: false,
      customerPostalCodeValid: false,
      customerTelephoneValid: false,
      customerDateOfBirthValid: false,
      cardNumberError: null,
      cardExpiryError: null,
      cardCvvError: null,
      customerEmailError: null,
      customerNameError: null,
      customerPostalCodeError: null,
      customerTelephoneError: null,
      errors: {},
      stripeErrors: null,
      showBillingAddressForm: false,
      disableCta: false,
      showStandBy: false,
      showConsentForm: false,
      isFocusedDate: false
    };
  }

  // Component Did Mount
  // ---------------------------------------------
  componentDidMount() {
    this.checkValidity('guests', this.state.guests);
    this.checkValidity('customerEmail', this.state.customerEmail);
    this.checkValidity('customerName', this.state.customerName);
    this.checkValidity('customerPostalCode', this.state.customerPostalCode);
    this.checkValidity('customerTelephone', this.state.customerTelephone);
  }

  // On Change
  // ---------------------------------------------
  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    if (e.target.classList.contains('invalid')) {
      this.checkValidity(e.target.name, e.target.value);
    }
    this.setState(stateChange);
    if (e.target.name === 'cardNumber') {
      this.setState({ cardTypeMask: cardTypeMask(e.target.value) });
    }
    if (e.target.name === 'guests') {
      this.props.updateGuests(e.target.value);
    }
  };

  onChangePhone = phone => {
    this.setState(
      {
        customerTelephone: phone
      },
      () => {
        this.checkValidity('customerTelephone', this.state.customerTelephone);
      }
    );
  };

  // On Blur
  // ---------------------------------------------
  onBlur = e => {
    this.checkValidity(e.target.name, e.target.value);
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    if (e.target.classList.contains('invalid')) {
      this.checkValidity(e.target.name, e.target.value);
    }
    this.setState(stateChange);
    if (e.target.name === 'cardNumber') {
      this.setState({ cardTypeMask: cardTypeMask(e.target.value) });
    }
    if (e.target.name === 'guests') {
      this.props.updateGuests(e.target.value);
    }
  };

  // Check Validity
  // ---------------------------------------------
  checkValidity = (type, val) => {
    let validity = false;
    let error = null;
    switch (type) {
      case 'guests':
        validity = validateGuests(val);
        error = checkErrorsGuests(val);
        break;
      case 'customerEmail':
        validity = validateCustomerEmail(val);
        error = checkErrorsCustomerEmail(val);
        break;
      case 'customerName':
        validity = validateCustomerName(val);
        error = checkErrorsCustomerName(val);
        break;
      case 'customerPostalCode':
        validity = validateCustomerPostalCode(val);
        error = checkErrorsCustomerPostalCode(val);
        break;
      case 'customerTelephone':
        validity = validateCustomerTelephone(val);
        error = checkErrorsCustomerTelephone(val);
        break;
    }

    const validityChange = {};
    const errorChange = {};
    validityChange[`${type}Valid`] = validity;
    errorChange[`${type}Error`] = error;
    this.setState(validityChange);
    this.setState(errorChange);
  };

  // Build Field Status
  // ---------------------------------------------
  buildFieldStatus = type => {
    const typeError = this.state[`${type}Error`];
    const typeValidity = this.state[`${type}Valid`];
    if (typeValidity === true) {
      return false;
    } else if (typeError === 'empty' || typeError === null) {
      return '';
    }
    return true;
  };

  // Setup Guests
  // ---------------------------------------------
  setupGuests = () => {
    const guestsArray = [];
    for (let i = 1; i <= this.props.max_guests; i++) {
      guestsArray.push(i);
    }
    return guestsArray;
  };

  // Fill From Contact
  // ---------------------------------------------
  fillFromContact = () => {
    this.setState({
      customerEmail: this.props.customerEmail || '',
      customerName: this.props.customerName || '',
      customerPostalCode: this.props.customerPostalCode || '',
      customerTelephone: this.props.customerTelephone || '',
      toggleFill: true
    });
    if (this.props.customerEmail) {
      this.checkValidity('customerEmail', this.props.customerEmail);
    }
    if (this.props.customerName) {
      this.checkValidity('customerName', this.props.customerName);
    }
    if (this.props.customerPostalCode) {
      this.checkValidity('customerPostalCode', this.props.customerPostalCode);
    }
    if (this.props.customerTelephone) {
      this.checkValidity('customerTelephone', this.props.customerTelephone);
    }
  };

  // Handle Submit
  // ---------------------------------------------
  handleSubmit = async () => {
    const { stripe, elements } = this.props;
    this.setState({ showStandBy: true, stripeError: null });

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log('ERROR STRIPE!');
      return;
    }
    if (!this.handleValidation()) {
      this.setState({ showStandBy: false });
      return;
    }
    const result = await stripe.confirmCardSetup(
      `${this.props.stripeIntentId}`,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: this.state.customerName,
            email: this.state.customerEmail,
            phone: this.state.customerTelephone,
            address: {
              postal_code: this.state.customerPostalCode
            }
          }
        }
      }
    );

    if (result.error) {
      this.setState({ showStandBy: false, stripeError: result.error });
    } else if (this.handleValidation()) {
      this.setState({ disableCta: true });
      if (this.props.chargeAmount > 0) {
        axios
          .post(
            `${process.env.DIRECT_URL}/api/v2/listings/${
              this.props.listing.id
            }/process_payment`,
            {
              charge_amount: parseFloat(this.props.chargeAmount),
              booking_id: this.props.booking.id,
              customer_email: this.state.customerEmail,
              customer_name: this.state.customerName,
              customer_telephone: this.state.customerTelephone,
              stripe_customer_id: this.props.stripeCustomerId,
              stripe_token: this.props.stripeCustomerId
            }
          )
          .then(() => {
            window.location = window.location;
            this.setState({ showStandBy: false });
          })
          .catch(data => {
            toast.error(data.response.data.error);
            window.location = window.location;
            this.setState({ showStandBy: false });
          });
      } else {
        axios
          .post(
            `${process.env.DIRECT_URL}/api/v2/checkout_booking/${
              this.props.listing.id
            }`,
            {
              unit_id: this.props.unit.id,
              booking_range: JSON.stringify(this.props.bookingDaysInclusive),
              check_in: this.props.checkInDate.format('DD-MM-YYYY'),
              check_out: this.props.checkOutDate.format('DD-MM-YYYY'),
              num_guests: this.state.guests,
              customer_email: this.props.customerEmail,
              customer_name: this.props.customerName,
              customer_telephone: this.props.customerTelephone,
              customer_date_of_birth: this.state.customerDateOfBirth,
              adr_street: this.state.adrStreet,
              adr_city: this.state.adrCity,
              adr_state: this.state.adrState,
              adr_country: this.state.adrCountry,
              adr_zip: this.state.adrPostalCode,
              addon_fee_ids: this.props.addonFeeIds,
              stripe_customer_id: this.props.stripeCustomerId,
              coupon_code: this.props.couponCode,
              room_type_booking: this.props.listing.is_room_type,
              quote_id: this.props.quoteId,
              delivery_location: this.props.deliveryLocation,
              consent_given: this.state.consentGiven
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          .then(response => {
            const data = response.data;
            if (this.props.brand_info.google_events) {
              gtag('event', 'purchase', {
                transaction_id: data.booking_code,
                affiliation: 'Direct',
                value: this.props.pricing.total,
                currency: 'USD',
                tax: this.props.pricing.taxes,
                shipping: 0
              });
            }
            if (this.props.brand_info.facebook_pixel) {
              fbq('track', 'Purchase', {
                currency: 'USD',
                value: this.props.pricing.total
              });
            }
            if (
              this.props.verifyImage ||
              this.props.verifySignature ||
              this.props.verifyAge ||
              this.props.verifyAddress
            ) {
              window.location = `${'/my-bookings/verification/' +
                `${this.props.slug}/`}${data.booking_code}${
                this.props.urlLocation?.search
              }`;
            } else {
              window.location = `${'/my-bookings/receipt/' +
                `${this.props.slug}/`}${data.booking_code}${
                this.props.urlLocation?.search
              }`;
            }
            this.setState({ showStandBy: false });
          })
          .catch(data => {
            toast.error('booking could not be processed, try again later');
            this.setState({ showStandBy: false });
          });
      }
    } else {
      this.setState({ showStandBy: false });
    }
  };

  isCtaEnabled = () => {
    if (this.checkAuthenticate()) {
      return (
        this.state.consentGiven &&
        this.state.customerPostalCodeValid &&
        this.state.customerNameValid &&
        this.state.customerEmailValid &&
        this.state.customerTelephoneValid &&
        this.state.customerDateOfBirthValid
      );
    }

    return (
      this.state.customerPostalCodeValid &&
      this.state.customerNameValid &&
      this.state.customerEmailValid &&
      this.state.customerTelephoneValid
    );
  };

  checkAuthenticate = () => {
    if (this.props.brand?.organization.integrations) {
      const authenticate = this.props.brand.organization.integrations.filter(
        integration => integration.integration_name === 'authenticate'
      )[0];

      if (
        authenticate &&
        authenticate.additional_integration_details &&
        authenticate.additional_integration_details.medallion_workflow_id
      ) {
        return true;
      }
    }
    return false;
  };

  handleValidation() {
    const errors = {};
    let formIsValid = true;

    if (!this.state.customerName) {
      formIsValid = false;
      errors.name = 'Name is required.';
    }
    if (!this.state.customerEmail) {
      formIsValid = false;
      errors.email = 'Email is required.';
    }
    if (!this.state.customerTelephone) {
      formIsValid = false;
      errors.phone = 'Phone is required.';
    }
    if (!this.state.customerPostalCode) {
      formIsValid = false;
      errors.postal = 'Postal code is required.';
    }
    if (this.state.showConsentForm && !this.state.consentGiven) {
      formIsValid = false;
      errors.consent = 'Consent is required.';
    }
    if (this.state.showConsentForm && !this.state.customerDateOfBirth) {
      formIsValid = false;
      errors.customerDateOfBirth = 'Date of Birth is required.';
    }

    this.setState({ errors });
    return formIsValid;
  }

  render() {
    const translate = ReactI18n.getIntlMessage;
    const guestsArray = this.setupGuests();

    return (
      <>
        <StandByOverlay
          reveal={this.state.showStandBy}
          position="fixed"
          headline="Completing your booking..."
        />
        <form onSubmit={this.handleSubmit}>
          {this.props.chargeAmount > 0 && (
            <>
              <FlexBox justifyContent="space-between" alignItems="center">
                <Box>
                  <TextH2>Charge Amount</TextH2>
                </Box>
                <Box>
                  <TextH2>
                    {formatCurrency(
                      this.props.chargeAmount,
                      this.props.currency
                    )}
                  </TextH2>
                </Box>
              </FlexBox>
              <Divider padding="s" />
            </>
          )}
          <CardSectionV2 />
          {this.state.stripeError && (
            <InfoPanel
              icon={<IconFontAwesome name="exclamationTriangle" />}
              color="red"
              headline="There was an error with your payment."
              description={this.state.stripeError.message}
            />
          )}
          <Spacer size="l" />
          <FlexBox alignItems="center" justifyContent="space-between">
            <TextH2>Billing Info</TextH2>
            {this.props.chargeAmount === undefined && (
              <FlexBox alignItems="center" gap="s">
                <TextDense textColor="dark-gray" weight="semibold">
                  Same as Contact Info
                </TextDense>
                <InputCheckbox
                  removeBottomSpacer
                  onChange={() => {
                    const newValue = !this.state.showBillingAddressForm;
                    this.setState({
                      showBillingAddressForm: newValue
                    });
                    if (newValue) {
                      this.fillFromContact();
                    } else {
                      this.setState({
                        toggleFill: false,
                        customerEmail: '',
                        customerName: '',
                        customerPostalCode: '',
                        customerTelephone: '',
                        customerEmailValid: false,
                        customerNameValid: false,
                        customerPostalCodeValid: false,
                        customerTelephoneValid: false,
                        customerEmailError: 'empty',
                        customerNameError: 'empty',
                        customerPostalCodeError: 'empty',
                        customerTelephoneError: 'empty'
                      });
                    }
                  }}
                  checked={this.state.showBillingAddressForm}
                />
              </FlexBox>
            )}
          </FlexBox>
          <Spacer size="s" />
          <Spacer size="xxs" />
          <ErrorsPaymentCustomer
            errors={[
              { param: 'customerName', code: this.state.customerNameError },
              { param: 'customerEmail', code: this.state.customerEmailError },
              {
                param: 'customerTelephone',
                code: this.state.customerTelephoneError
              },
              {
                param: 'customerPostalCode',
                code: this.state.customerPostalCodeError
              },
              { param: 'guests', code: this.state.guestsError }
            ]}
            translate={this.props.translate}
          />
          <FormField
            labelText="Full Name"
            labelHtmlFor="customerName"
            inputProps={{
              name: 'customerName',
              onChange: e => this.onChange(e),
              value: this.state.customerName,
              onBlur: e => this.onBlur(e),
              isError: this.buildFieldStatus('customerName'),
              description: this.state.errors.name,
              placeholder: 'Jane Smith',
              inputWidth: 'auto'
            }}
          />
          <FlexBox gap="s">
            <Box flex="1">
              <FormField
                labelText="Email"
                labelHtmlFor="customerEmail"
                inputProps={{
                  type: 'email',
                  name: 'customerEmail',
                  onChange: e => this.onChange(e),
                  value: this.state.customerEmail,
                  onBlur: e => this.onBlur(e),
                  isError: this.buildFieldStatus('customerEmail'),
                  description: this.state.errors.email,
                  placeholder: 'name@email.com',
                  inputWidth: 'auto'
                }}
              />
            </Box>
            <Box flex="1">
              <FormField
                labelText="Phone"
                labelHtmlFor="customerTelephone"
                inputType="phone"
                inputProps={{
                  type: 'tel',
                  name: 'customerTelephone',
                  onChange: phone => this.onChangePhone(phone),
                  value: this.state.customerTelephone,
                  onBlur: e => this.onBlur(e),
                  isError: this.state.errors.phone,
                  description: this.state.errors.phone,
                  placeholder: '+1 (123) 123-1234',
                  inputWidth: 'auto'
                }}
              />
            </Box>
          </FlexBox>
          <FormField
            labelText="Postal Code"
            labelHtmlFor="customerPostalCode"
            inputType="text"
            inputProps={{
              name: 'customerPostalCode',
              onChange: e => this.onChange(e),
              value: this.state.customerPostalCode,
              onBlur: e => this.onBlur(e),
              isError: this.buildFieldStatus('customerPostalCode'),
              description: this.state.errors.postal,
              placeholder: '12345',
              maxLength: 15,
              inputWidth: 's',
              removeBottomSpacer: true
            }}
          />
          <Box>
            {this.props.chargeAmount === undefined ||
              (this.props.chargeAmount === '' && (
                <Box>
                  <Label htmlFor="guests">
                    <span>Number of Guests</span>
                  </Label>
                  <select
                    className={this.buildFieldStatus('guests')}
                    name="guests"
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    value={this.state.guests}
                    required
                  >
                    {guestsArray.map(guest => (
                      <option value={guest} key={guest}>
                        {translate(
                          `global.parsers.num_guests.${
                            guest > 1 ? 'plural' : 'single'
                          }`,
                          { num: guest }
                        )}
                      </option>
                    ))}
                  </select>
                </Box>
              ))}
          </Box>
          <Spacer size="l" />
          {this.props.listing.refund_policy && (
            <>
              <Divider />
              <Spacer size="l" />
              <TextH2>{translate('cx.checkout.cancel_policy')}</TextH2>
              <Spacer size="s" />
              <Spacer size="xxs" />
              <TextBody textColor="dark-gray" isFullWidth weight="semibold">
                {translate(
                  `global.refund_policy.${
                    this.props.listing.refund_policy
                  }.label`
                )}
              </TextBody>
              {this.props.listing.refund_policy === 'custom' ? (
                <TextBody>{this.props.listing.refund_policy_custom}</TextBody>
              ) : (
                <TextBody>
                  {translate(
                    `global.refund_policy.${
                      this.props.listing.refund_policy
                    }.details`
                  )}
                </TextBody>
              )}
            </>
          )}
          <Spacer size="l" />
          <Divider />
          <Spacer size="l" />
          {this.checkAuthenticate() && (
            <>
              <FormField
                labelText="Date of Birth"
                labelHtmlFor="customerDateOfBirth"
                inputType="date"
                inputProps={{
                  name: 'customerDateOfBirth',
                  onDateChange: date =>
                    this.setState({ customerDateOfBirth: date }),
                  date: this.state.customerDateOfBirth,
                  onBlur: e => this.onBlur(e),
                  description: this.state.errors.customerDateOfBirth,
                  isFocused: this.state.isFocusedDate,
                  onFocusChange: ({ focused }) =>
                    this.setState({ isFocusedDate: focused }),
                  inputWidth: 'auto'
                }}
              />
              <Spacer size="l" />
            </>
          )}
          {this.checkAuthenticate() && (
            <>
              <FlexBox gap="s">
                <InputCheckbox
                  onChange={() =>
                    this.setState({
                      consentGiven: !this.state.consentGiven
                    })
                  }
                />
                <label htmlFor="consentCheckbox">
                  {' '}
                  By clicking this checkbox, you are agreeing to pay the full
                  amount of the booking, and you are consenting to the necessary
                  checks and verifications for insurance or other background
                  research purposes listed{' '}
                  <a onClick={() => this.setState({ showConsentForm: true })}>
                    here
                  </a>{' '}
                </label>
              </FlexBox>
              <Modal
                title="Consent Form"
                reveal={this.state.showConsentForm}
                size="m"
                closeOnClick={() => this.setState({ showConsentForm: false })}
              >
                <Modal.Content contentIsScrollable>
                  <Box paddingVertical="s" paddingHorizontal="xs">
                    <div>
                      Authorization to Obtain a Consumer Report Pursuant to the
                      federal Fair Credit Reporting Act (“FCRA”), I hereby
                      authorize Authenticating.com LLC and its designated agents
                      and representatives to conduct a comprehensive review of
                      my background through a consumer report and/or an
                      investigative consumer report that may be used as a factor
                      in establishing my eligibility for credit, insurance or
                      for any other purpose in the FCRA. I understand that the
                      scope of the consumer report/investigative consumer report
                      may include, but is not limited to, the following areas:
                      verification of Social Security number; current and
                      previous residences; employment history, including all
                      personnel files; education; references; credit history and
                      reports; criminal history, including records from any
                      criminal justice agency in any or all federal, state or
                      county jurisdictions; birth records; motor vehicle
                      records, including traffic citations and registration; and
                      any other public records.
                      <br />
                      I, {this.state.customerName} authorize the complete
                      release of these records or data pertaining to me that an
                      individual, company, firm, corporation or public agency
                      may have. I hereby authorize and request any present or
                      former employer, school, police department, financial
                      institution or other persons having personal knowledge of
                      me to furnish Authenticating.com LLC or its designated
                      agents with any and all information in their possession
                      regarding me in connection with the use of Direct
                      Software's product, service or experience I am signing up
                      for. I am authorizing that a photocopy of this
                      authorization be accepted with the same authority as the
                      original. I understand that, pursuant to the federal Fair
                      Credit Reporting Act, if any adverse action is to be taken
                      based upon the consumer report, a copy of the report and a
                      summary of the consumer’s rights will be provided to me. I
                      further understand that by typing my name here I am
                      signing this authorization electronically.
                    </div>
                  </Box>
                </Modal.Content>
              </Modal>
              <Spacer size="l" />
            </>
          )}
          <CheckoutCtaV2
            {...this.props}
            handleSubmit={this.handleSubmit}
            isCtaEnabled={this.isCtaEnabled()}
            disableCta={this.state.disableCta}
          />
          <Spacer size="l" />
        </form>
      </>
    );
  }
}

export default function InjectedCardSetupFormV2(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardSetupFormV2
          stripe={stripe}
          elements={elements}
          addonFeeIds={props.addonFeeIds}
          availability={props.availability}
          booking={props.booking}
          bookingDaysInclusive={props.bookingDaysInclusive}
          brand_info={props.brand_info}
          brand={props.brand}
          chargeAmount={props.chargeAmount}
          checkInDate={props.checkInDate}
          checkOutDate={props.checkOutDate}
          couponCode={props.couponCode}
          currency={props.currency}
          customerEmail={props.customerEmail}
          customerName={props.customerName}
          customerPostalCode={props.customerPostalCode}
          customerTelephone={props.customerTelephone}
          guests={props.guests}
          listing={props.listing}
          max_guests={props.max_guests}
          pricing={props.pricing}
          quoteId={props.quoteId}
          rental_agreement={props.rental_agreement}
          slug={props.slug}
          stripeCustomerId={props.stripeCustomerId}
          stripeIntentId={props.stripeIntentId}
          translate={props.translate}
          unit={props.unit}
          updateGuests={props.updateGuests}
          verifyImage={props.verifyImage}
          verifySignature={props.verifySignature}
          verifyAge={props.verifyAge}
          verifyAddress={props.verifyAddress}
          urlLocation={props.urlLocation}
          deliveryLocation={props.deliveryLocation}
        />
      )}
    </ElementsConsumer>
  );
}
